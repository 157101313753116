<template>
  <v-container style="max-width: 600px; margin: 0 auto">
    <div style="white-space: pre-line;">
      {{ text }}
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Terms',
    data: () => ({
      text: `レコセレ基本規約
レコセレ基本規約には、「レコセレ」（以下に定義されます。以下同じです。）の提供条件が定められています。レコセレの利用に際しては、レコセレ規約（以下に定義されます。以下同じです。）をよくお読みいただいたうえで、レコセレ規約に同意していただく必要があります。
第１条（用語の定義）
（１）「レコセレ」とは、レコセレ（以下に定義されます。以下同じです。）が運営する“レコセレ”を意味します。
（２）「レコセレ」とは、株式会社レコセレを意味します。
（３）「販売者」とは、レコセレに販売商品（以下に定義されます。以下同じです。）を出品するサービス利用者を意味します。
（４）「購入者」とは、レコセレにおいて販売商品を購入するサービス利用者を意味します。
（５）「サービス利用者」とは、販売者、購入者及びスペース提供者を意味します。
（６）「販売者情報」とは、販売者がレコセレに登録する必要のある情報としてレコセレが指定した情報を意味します。
（７）「購入者情報」とは、購入者がレコセレに登録する必要のある情報としてレコセレが指定した情報を意味します。
（８）「販売商品」とは、レコセレ上で出品され、又は購入される物品をいいます。
（９）「販売商品情報」とは、販売者がレコセレに登録する販売商品に関する情報を意味します。
（１０）「販売代金」とは、販売商品の消費税別の対価を意味します。
（１１）「販売代金等」とは、販売代金、販売商品の送料、手数料、及びこれらにかかる消費税相当額の合計を意味します。
（１２）「レコセレサービス」とは、レコセレを利用するためのウェブサイト及びアプリケーションを意味します。
（１３）「受取店舗」とは、購入者が販売商品の受取・保管場所として、または販売者が販売商品の出荷・保管場所としてレコセレサービス上で指定する場所をいいます。
（１４）「基本規約」とは、このレコセレ基本規約を意味します。
（１５）「出品規約」とは、レコセレが別途定めるレコセレ出品規約を意味します。
（１６）「購入規約」とは、レコセレが別途定めるレコセレ購入規約を意味します。
（１７）「レコセレ規約」とは、基本規約、出品規約、購入規約及びレコセレ受取店舗導入規約を意味します。
（１８）「レコセレ利用契約」とは、レコセレ規約の内容を契約条件に含む、第３条第５項の規定に従い、レコセレとサービス利用者との間で締結されるレコセレの利用契約を意味します。
（１９）「サービス利用登録」とは、サービス利用者としてレコセレに登録することを意味します。
（２０）「商品売買契約」とは、レコセレを通じて行われる、販売者と購入者の間の、販売商品に関する売買に関する契約を意味します。
（２１）「投稿データ」とは、サービス利用者がレコセレを利用して投稿、その他送信するコンテンツ（文章、画像、動画その他のデータを含みます。）を意味します。
（２２）「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
（２３）「不具合等」とは、販売商品に関する、商品の未達、商品違い、数量不足、梱包不良、品質不良又はその他の不具合を意味します。
（２4 ）「スペース提供者」とは、販売商品を預かるスペースを提供する事業者を意味します。
（２5 ）「スペース提供規約」とは、レコセレが別途定めるレコセレスペース提供規約を意味します。
第２条（適用される規約）
サービス利用者がレコセレを利用する場合には、レコセレ利用規約の承諾及び当該規約に基づく登録手続きも必要です。なお、レコセレ利用規約の定めとレコセレ規約の定めが矛盾・重複する場合には、レコセレ規約の定めが優先的に適用されるものとします。
前項の定めに関わらず、レコセレの判断により、レコセレ利用規約の承諾及び当該規約に基づく登録手続きなしにレコセレを利用することができる場合があります。
サービス利用者がレコセレ上に販売商品を出品する場合、基本規約に加えて出品規約が適用され、サービス利用者がレコセレ上で販売商品を購入する場合、基本規約に加えて購入規約が適用され、スペース提供者がレコセレ上に販売商品を預かる場合、基本規約に加えてスペース提供規約が適用されます（但し、レコセレ規約に基づき締結される商品売買契約に基づき他の当事者が購入者又は販売者として有する権利・利益の制約を受けますので、その範囲では、その他のレコセレ規約の適用も受けます）。なお、基本規約の内容と、出品規約又は購入規約の内容とが異なる場合は、出品規約及び購入規約の規定が優先して適用されるものとします。
レコセレは、レコセレ規約の他に、別途レコセレの利用に関するルール等を定める場合があります。その場合、当該ルール等は、レコセレ規約とともに適用されるものとします。なお、レコセレ規約の内容と、当該ルール等とが異なる場合は、当該ルール等に特段の定めがある場合を除き、レコセレ規約の規定が優先して適用されるものとします。
第３条（サービス利用登録）
レコセレの利用を希望する者は、レコセレが指定する情報（以下、本条において「登録事項」といいます。）を、レコセレが指定する方法でレコセレに提供し、レコセレ規約の内容に同意することを確認したうえで、レコセレに対し、サービス利用登録を申請することができます。
前項に基づいてサービス利用登録の申請を行った者（以下、本条において「登録申請者」といいます。）のサービス利用登録は、レコセレが当該登録申請者の登録事項をレコセレに登録したことをもって完了します。
登録申請者が未成年者、被保佐人又は被補助人のいずれかである場合には、親権者、保佐人又は補助人の同意が必要となる場合がありますので、必ず、レコセレ規約を事前に保護者にもお読みいただいた上でご利用ください。未成年者、被保佐人又は被補助人がサービス利用者として登録される場合には、親権者、保佐人又は補助人のご同意をいただいたものとみなされます。
レコセレは、サービス利用登録の条件として、登録申請者に一定の情報の追加提供を求める場合があります。
レコセレ利用契約は、サービス利用登録の完了をもってレコセレと登録申請者との間で成立します。
レコセレは、様々な理由によりサービス利用登録を拒否することがありますが、レコセレは拒否した理由を開示する義務を負いません。
登録申請者は、サービス利用登録を拒否された場合に、レコセレの判断に異議を唱えることはできず、また、損害賠償請求をすることもできません。
サービス利用者は、登録事項に変更があった場合、レコセレの定める方法により変更内容をすみやかにレコセレに通知しなければなりません。
サービス利用者からの登録事項の変更の届出がないことにより生じた販売商品、通知及び入金等の不着及び遅延等について、レコセレ及び他のサービス利用者は責任を負わないものとします。
登録申請者により提供された登録事項に虚偽、記入漏れ等があった場合、当該虚偽等に起因して当該登録申請者に損害が生じたとしても、レコセレ及び他のサービス利用者は責任を負いません。
第４条（ログイン情報の管理）
サービス利用者は、レコセレへのログインに用いる情報（以下、本条において「ログイン情報」といいます。）を、自己の責任の下で管理するものとします。
レコセレは、レコセレのログイン時に送信された情報が、サービス利用者のログイン情報と一致することを所定の方法により確認した場合、当該ログインを当該サービス利用者によるログインとみなし、また、当該ログイン後のレコセレの利用を当該サービス利用者による利用とみなします。
前項の場合において、ログイン情報の盗用、不正利用その他の事由により当該サービス利用者以外の第三者がレコセレを利用しているときであっても、レコセレは、それにより当該サービス利用者に生じた損害について責任を負わないものとします。
第５条（サービスの利用）
サービス利用者は、サービス利用契約の有効期間中、レコセレ利用契約の条件に従ってレコセレを利用することができます。

第６条（契約関係）
レコセレ利用契約は、レコセレと個々のサービス利用者間で締結されるレコセレの利用に関する契約であり、販売者及び購入者間の契約である商品売買契約とは異なる契約です。
商品売買契約は、販売者を売主、購入者を買主とする売買契約であり、レコセレは販売者に該当しない場合、商品売買契約の当事者とはなりません。そのため、商品売買契約において、製造物責任法上の「製造業者等」となり得るのは、あくまで販売者であり、レコセレは販売者に該当しない場合製造物責任法上の「製造業者等」となり得えません。
購入者がレコセレサービスにおいて販売商品の注文を確定し、レコセレが販売者のために購入者から注文を受けることにより、当該販売商品の購入の申込みが完了します。当該購入の申込みは承諾の通知を必要としないものとし、承諾の意思表示と認めるべき事実として、販売者から配送等の委託を受けたレコセレ（又はレコセレよりさらに委託を受けた配送業者）が販売商品を確保した事実があった時点または販売者がレコセレが指定する商品出荷場所への出荷を完了した時点で、商品売買契約が成立します。
レコセレ（又はレコセレよりさらに委託を受けた配送業者）が販売商品を確保できなかった場合、購入者に対してすみやかにその旨が通知され、商品売買契約は成立しなかったこととなります。
レコセレは販売者からの委託を受け、購入者が指定した受取店舗への販売商品の配送を行なうものとします。なお、レコセレは、当該配送を自社で行う又は他の配送業者に再委託します。
レコセレは、購入者が販売商品を受領し、これを自ら保管するための場所である受取店舗の利用を、販売者に対して許諾します。販売者は、これに基づき、購入者による販売商品の受領及び保管のために、受取店舗の利用を購入者に対して許諾します。
レコセレは、販売商品の出品、購入及び決済を行うプラットフォームの提供及びレコセレ規約に基づく販売者に対する一定のサービスの提供を行うものであり、レコセレは販売者に該当しない場合、販売商品の不具合等に関しては購入者に対しては何らの責任も負いません。
レコセレは、販売商品について不具合等のクレームがあり、合理的期間内に当該クレームが解決されない場合、及び販売者又は購入者とのサービス利用契約が終了した場合、販売者及び購入者に通知することにより、当該販売商品又は当該販売者若しくは当該購入者にかかる商品売買契約を解除することができるものとし、販売者及び購入者はこれを受け入れなければなりません。
第７条（禁止事項）
レコセレの利用に関し、以下の行為は禁止されています。
（１）法令、条例等に違反する行為又は犯罪に関連する行為
（２）一人のサービス利用者が複数の購入者の地位を保有する行為（但し、当社が別途指定する場合を除きます。）
（３）レコセレ、サービス利用者又はその他第三者に対する詐欺又は脅迫行為
（４）公序良俗に反する行為
（５）レコセレ、サービス利用者又はその他第三者の知的財産権、肖像権、プライバシーに関する権利、名誉、その他の権利又は利益を侵害する行為
（６）以下のいずれかに該当し、又は該当するとレコセレが判断する情報をレコセレを通じて送信すること
第三者の権利を侵害する情報
過度に暴力的又は残虐な表現を含む情報
コンピューターウィルスその他の有害なコンピュータープログラムを含む情報
レコセレ又は第三者の名誉又は信用を毀損する表現を含む情報
わいせつで、不適当な表現を含む情報
差別を助長する表現を含む情報
自殺、自傷行為を助長する表現を含む情報
薬物の不適切な利用を助長する表現を含む情報
反社会的な表現を含む情報
チェーンメール等の第三者への情報の拡散を求める情報
不快感を与える表現を含む情報
面識のない異性との出会いを目的とした情報
その他レコセレの目的上不適切である情報
（７）レコセレにおける個人情報の入力欄以外に個人情報を入力し、送信する行為
（８）レコセレがレコセレの提供のために用いているネットワーク又はシステム等に過度な負荷をかけ、又は不正にアクセスする行為
（９）レコセレの運営を妨害するおそれのある行為
（１０）レコセレサービス及びレコセレがレコセレの提供のために用いているソフトウェアのリバースエンジニアリングその他の解析行為
（１１）第三者に成りすます行為
（１２）レコセレが事前に許諾していないレコセレ上での宣伝、広告、勧誘、又は営業行為（出品中の販売商品の宣伝等については、本号の対象に含まれません。）
（１３）レコセレを経由しない又は迂回する販売商品に関する取引の勧誘（これに類似する直接又は間接の行為を含みます。）
（１４）レコセレを経由しない又は迂回する販売代金等その他の商品売買契約に関する金銭の請求及び支払い
（１５）他のサービス利用者の情報の収集
（１６）レコセレ又は第三者に不利益、損害、不快感を与える行為
（１７）反社会的勢力への利益供与
（１８）面識のない異性との出会いを目的とした行為
（１９）前各号の行為を直接若しくは間接に惹起し、又は容易にする行為
（２０）前各号の行為を試みる行為
（２１）前各号の他、レコセレが不適切と判断する行為
第８条（サービスの提供の中断）
 以下の各号のいずれかに該当した場合、レコセレは、サービス利用者に事前に通知等することなく、レコセレの全部又は一部の提供を中断することができるものとします。
（１）レコセレにかかるネットワーク又はシステム等の点検又は保守作業を行う場合
（２）レコセレにかかるネットワーク又はシステム等が停止した場合
（３）レコセレにアクセスが集中した場合
（４）レコセレに対して不正なアクセスがあった場合又はその疑いがあるとレコセレが判断した場合
（５）前各号の他、レコセレがレコセレの提供を中断する必要があると判断した場合
レコセレは、前項に基づくレコセレの提供の中断によりサービス利用者に損害が生じた場合であっても、事由のいかんを問わず、当該損害について一切責任を負わないものとします。
第９条（レコセレ及びレコセレサービスに関する権利の帰属）
レコセレ及びレコセレサービスに関する知的財産権は、全てレコセレ又はレコセレにその利用を許諾している第三者に帰属しています。
レコセレは、明示的に意思表示をした場合を除き、サービス利用者に対し、レコセレ及びレコセレサービスに関する知的財産権を譲渡し、又は利用許諾するものではありません。
第１０条（投稿データに関する権利の帰属）
サービス利用者は、投稿データをレコセレに送信し、第３項に定める利用許諾を行う権限を有していることを保証するものとします。
サービス利用者が投稿データをレコセレに送信することにより、投稿データに関する知的財産権がレコセレ又は第三者に移転することはありません。
サービス利用者は、レコセレに対し、投稿データを利用・改変（投稿データに含まれる文章の短縮及び抜粋並びに投稿データに含まれる写真の切り抜き、拡大及び縮小を含みます。）する非独占的な権利を無償で許諾するものとします。
サービス利用者は、前項に基づく投稿データの利用に関し、投稿データに関する著作者人格権を行使し、又は第三者に行使させないことに同意するものとします。
第１１条（利用環境の整備）
サービス利用者は、レコセレを利用するために必要な機器その他の環境を自己の費用と責任において準備し、利用可能な状態に置くものとします。また、レコセレの利用にあたっては、自己の費用と責任において、利用者が任意に選択した電気通信サービス及び電気通信回線を経由してインターネットに接続するものとします。
サービス利用者は、関係官庁等が提供する情報を参考にして、自己の利用環境に応じ、コンピューターウィルスの感染、不正アクセス及び情報漏洩の防止等のセキュリティ対策を講じるものとします。
レコセレは、サービス利用者の利用環境について一切関与せず、また一切の責任を負いません。
第１２条（費用負担）
レコセレ規約に別段の定めがある場合を除き、サービス利用者の義務の履行に際して必要になる費用は、当該サービス利用者が負担するものとします。
販売代金等の全部又は一部が返金される場合、当該返金に要する手数料は、販売者が負担するものとします。但し、当該返金の原因が購入者の責に帰すべき事由による場合は、返金に要する手数料は、購入者が負担するものとし、当該返金の原因がレコセレの責に帰すべき事由による場合は、返金に要する手数料は、レコセレが負担するものとします。
販売代金等の決済が取り消される場合における当該決済及び決済取消しにかかる費用についても、前項の定めに準じて決定されるものとします。
第１３条（相殺）
サービス利用者は、レコセレ利用契約に関してサービス利用者がレコセレに対して有する債権とレコセレがサービス利用者に対して有する債権とを相殺することはできないものとします。
レコセレは、サービス利用者に通知することにより、レコセレがサービス利用者に対して有する債権とサービス利用者がレコセレに対して有する債権とを、各債権の弁済期のいかんを問わず相殺することができるものとします。
第１４条（自己責任の原則）
サービス利用者は、サービス利用者自身の責任においてレコセレを利用するものとし、レコセレを利用してなされた一切の行為及びその結果についてその責任を負うものとします。
サービス利用者は、レコセレの利用に関し、他のサービス利用者その他の第三者又はレコセレに損害又は不利益を与えた場合、自己の責任と費用においてこれを解決するものとします。
第１５条（サービス利用登録の抹消等）
 レコセレは、サービス利用者が以下の各号のいずれかに該当するとレコセレが判断した場合、事前にサービス利用者に通知することなく、当該サービス利用者がレコセレに送信した投稿データを非表示若しくは削除し、又は当該サービス利用者についてレコセレの利用を一時的に停止し、若しくはサービス利用登録を抹消することができるものとします。
（１）レコセレ利用契約の条件に違反した場合
（２）虚偽の情報を用いてサービス利用登録を行った場合
（３）支払停止若しくは支払不能状態になり、又は破産、民事再生、会社更生、特別清算若しくはこれらに類する手続きの開始の申立てがあった場合
（４）６ヶ月以上レコセレの利用がない場合
（５）レコセレからの問い合わせに対して３０日間以上応答がない場合
（６）未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、親権者、後見人、保佐人又は補助人の同意を得ずにサービス利用登録の申込みを行い、かつ、追認を得ていないことが判明した場合
（７）反社会的勢力（暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等を含みます。以下同じです。）である、又は資金提供その他の方法で反社会的勢力の維持、運営若しくは経営に協力若しくは関与する等、反社会的勢力に何らかの関与がある場合
（８）過去、サービス利用契約その他のレコセレと締結した契約に違反した者又はその関係者である場合
（９）前各号の他、投稿データの表示又はサービス利用登録を維持することが適当でないとレコセレが判断した場合
レコセレは、前項に基づく投稿データの非表示若しくは削除、レコセレの利用停止又はサービス利用登録の抹消によりサービス利用者に損害が生じた場合であっても、当該損害について一切責任を負わないものとします。
第１項各号のいずれかに該当したサービス利用者又はレコセレの登録を抹消されたサービス利用者は、レコセレに対して負っている債務の一切について期限の利益を失い、直ちにレコセレに対して全ての債務の支払を行わなければなりません。
サービス利用者は、レコセレが別途定める手続きを完了することにより、自己のサービス利用登録を抹消することができます。
サービス利用登録抹消後の問い合わせ対応等の必要性があることに鑑み、レコセレは、サービス利用登録の抹消後も、サービス利用登録を抹消したサービス利用者に関する情報の保有を継続することができるものとします。
レコセレ利用契約は、サービス利用登録の抹消をもって終了します。
レコセレ利用契約の終了前に発生した商品売買契約に基づく責任又はレコセレ利用契約に基づく責任は、サービス利用登録の抹消及びレコセレ利用契約の終了によって消滅しません。
第１６条（サービス内容の変更・終了）
レコセレは、レコセレの都合により、レコセレの内容及び提供条件を随時変更し、又はレコセレの提供を終了することができます。
レコセレは、レコセレの提供を終了する場合、サービス利用者に対して、レコセレが選択する方法により、事前に通知します。
レコセレの提供が終了した場合、同時にレコセレ利用契約も自動的に終了します。
レコセレは、第１項に基づくレコセレの内容若しくは提供条件の変更又はレコセレの提供終了によりサービス利用者に損害が生じた場合であっても、当該損害について一切責任を負わないものとします。
第１７条（保証の否認及び免責）
 レコセレは、以下の各号の事項について何ら保証するものではなく、当該事項を満たさなかったことに起因してサービス利用者に損害が生じた場合であっても、当該損害について一切責任を負わないものとします。
（１）レコセレがサービス利用者の特定の目的に適合すること
（２）レコセレが、サービス利用者の期待する機能・価値・正確性・有用性を有すること
（３）サービス利用者によるレコセレの利用が、サービス利用者に適用される法令、条例等又は業界団体の内部規則等に適合すること
（４）レコセレが中断なく利用できること
（５）レコセレに不具合が生じないこと
レコセレに関し、サービス利用者と他のサービス利用者その他の第三者との間でトラブルが生じた場合、レコセレは当該トラブルについて一切の責任を負わないものとします。
レコセレがレコセレに関してサービス利用者に損害賠償責任を負う場合、過去１ヶ月間に当該サービス利用者がレコセレに現に支払ったサービス利用料金の合計額を賠償金額の上限とし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、これを賠償する責任を負わないものとします。但し、レコセレの故意又は重過失によってサービス利用者に損害が生じた場合には、本項の規定は適用されないものとします。
第１８条（秘密保持）
サービス利用者は、レコセレの利用に関連してレコセレがサービス利用者に対して秘密に取り扱うことを求めて開示した情報について、レコセレの事前の承諾がある場合を除き、第三者に開示又は漏洩してはならないものとします。
第１９条（利用者情報の取り扱い）
レコセレによるサービス利用者の個人情報の取り扱いについては、レコセレが別途定めるプライバシーポリシー（URL：https://www.recosele.com/articles/privacy） の定めによるものとし、サービス利用者は、このプライバシーポリシーに従ってレコセレがサービス利用者の個人情報を取り扱うことについて同意するものとします。
レコセレは、サービス利用者がレコセレに提供し、又はレコセレが収集したサービス利用者に関する情報を、レコセレが提供するサービスの円滑な運営、提供及び改善のために必要な範囲で利用することができるものとします。
サービス利用者は、サービス利用者の個人情報を、第１項のプライバシーポリシーに定められた個人情報の第三者提供に関する定めに従い、レコセレが第三者に提供することに同意します。
サービス利用者は、サービス利用者間の識別のため、ニックネーム等がレコセレサービス上に表示されることに同意するものとします。
レコセレは、サービス利用者がレコセレに提供し、又はレコセレが収集したサービス利用者に関する情報を、個人を特定できないよう統計的に処理した上で、自ら利用し、また第三者に提供及び開示することができるものとします。
第２０条（通知方法）
レコセレがサービス利用者に対し、レコセレサービス上での通知、又はサービス利用者がレコセレに登録したLine個人アカウント又はメールアドレス宛のメールの送信により通知を行った場合、サービス上での通知の発信又はメール又はレコセレLine公式アカウントでの送信の実行により、レコセレからサービス利用者への意思表示又は事実の通知は完了したものとみなします。
第２１条（規約の変更）
レコセレは、民法（明治29年法律第89号）その他の法令により認められる場合には、サービス利用者の承諾を得ることなく、レコセレ規約を変更することができるものとします。
レコセレは、レコセレ規約を変更した場合、変更後のレコセレ規約をレコセレサービス上に掲載するものとします。
第２２条（契約上の地位の譲渡等）
サービス利用者は、レコセレの事前の承諾なくレコセレ利用契約上の地位又はレコセレ利用契約に基づく権利若しくは義務について、第三者に対し、譲渡、移転、担保設定その他の処分をすることはできません。
レコセレは、レコセレにかかる事業を事業譲渡及び会社分割その他の方法（以下「事業譲渡等」といいます。）を用いて第三者に譲渡する場合には、当該事業譲渡等に伴ってレコセレ利用契約上の地位、レコセレ利用契約に基づく権利及び義務並びにサービス利用者に関する情報を当該事業譲渡等の譲受人に譲渡等することができるものとし、サービス利用者は、かかる譲渡等についてあらかじめ同意したものとします。
第２３条（規約一部無効時の処理）
レコセレ規約のいずれかの条項又はその一部が消費者契約法その他の法令等によって無効又は執行不能と判断された場合であっても、無効又は執行不能と判断された部分以外は継続して効力を有するものとします。
第２４条（存続条項）
第６条（契約関係）、第９条（レコセレ及びレコセレサービスに関する権利の帰属）、第１０条（投稿データに関する権利の帰属）、第１２条（費用負担）、第１３条（相殺）、第１５条（サービス利用登録の抹消等）第２項、第３項、第５項、第７項及び第８項、第１６条（サービス内容の変更・終了）第４項、第１７条（保証の否認及び免責）、第１８条（秘密保持）、第１９条（利用者情報の取り扱い）、第２０条（通知方法）、第２２条（契約上の地位の譲渡等）、第２３条（規約一部無効時の処理）、本条、第２５条（準拠法）並びに第２６条（管轄）の各規定は、レコセレ利用契約の終了後もなお有効に存続するものとします。
第２５条（準拠法）
レコセレ規約、レコセレ利用契約及び商品売買契約の準拠法は日本法とします。
レコセレ及びサービス利用者は、レコセレ利用契約及び商品売買契約に関し、国際物品売買契約に関する国際連合条約の適用を排除することについて合意します。
第２６条（管轄）
レコセレ規約又はレコセレ利用契約に起因し、又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
以上
２０ 2  2年1  0月6日 制定
レコセレ購入規約
このレコセレ購入規約には、レコセレ上に出品されている販売商品を購入する際に適用される条件が定められています。レコセレで商品を購入する際には、レコセレ基本規約及びレコセレ購入規約に同意いただく必要がございますので、予めレコセレ基本規約及びレコセレ購入規約をお読みください。
第１条（用語の定義）
購入規約における用語の意味は、購入規約に別段の定めがあるものを除き、別途レコセレが定めるレコセレ基本規約の定義に従います。
第２条（購入者情報の登録等）
購入者情報がレコセレに登録されていないサービス利用者は、レコセレが指定する方法で購入者情報をレコセレに提供することにより、購入者情報の登録を申請することができます。
購入者情報がレコセレに登録されたサービス利用者は、購入者としてレコセレ上に出品された販売商品を購入することができます。
レコセレに登録された購入者情報は、購入した販売商品の発送に必要な範囲で販売者及び配送業者に開示され、また販売代金等の決済に必要な範囲でレコセレが当該決済に関する業務を委託する第三者に開示されます。
レコセレに登録された購入者情報に変更が生じ、又は誤記が含まれていた場合、購入者は、直ちに購入者情報の変更又は訂正を行わなければなりません。
第３条（購入手続き）
購入者が、基本規約の規定に従い、レコセレサービスにおいて販売商品の注文を確定することにより当該販売商品の購入の申込みが完了します。
購入者が販売商品の配送先として指定できる住所は、レコセレがレコセレサービスにおいて受取店舗として指定した場所に限るものとします。
第４条（購入のキャンセル）
販売商品の購入の申込み完了後は、販売者の同意を得た場合及びレコセレ規約に別段の定めがある場合を除き、購入者は申込みの撤回、商品売買契約の解除（以下「キャンセル」といいます。）をすることができません。
販売商品の購入の申込み完了後、販売商品の配達完了登録までの期間において、販売代金等の決済が取り消された場合その他販売代金等の支払いを受けられないおそれが生じたとレコセレが判断した場合、レコセレは、別途レコセレが指定する方法により、損害賠償その他の義務を負うことなく当該商品売買契約を解除することができ、購入者はこれを受け入れなければなりません。
販売商品の購入のキャンセルは、別途レコセレが指定する方法により行うものとします。
第５条（販売代金等の支払い）
販売代金等は、レコセレが指定する決済方法の中から購入者が選択した方法を用いて決済されます。
前項に定める方法により販売代金等の決済ができない場合、その原因を問わず、購入者はレコセレが指定する方法により販売代金等をレコセレに対して支払うものとします。なお、レコセレ又はレコセレの委託先の責に帰すべき原因により販売代金等の決済ができなかった場合を除き、本項に基づく支払いを行うために必要となる振込手数料その他の費用は、購入者が負担するものとします。
購入者は、別途レコセレの明示的な同意を得た場合を除いて、販売者に直接販売代金等の支払いを行ってはならないものとします。なお、本項に違反して購入者が販売者に直接販売代金等の支払いを行った場合でも、当該支払いは、販売代金等の弁済とはみなされないものとします。
第６条（返金）
販売代金等の支払後の商品売買契約の解除その他の原因により販売代金等の返金を行う必要が生じた場合、購入者は、レコセレの指定する方法により、購入者名義の返金先金融機関口座を通知するものとします。
前項に規定する場合において、購入者が一定期間内に返金先金融機関口座を指定せず、又は購入者が指定した金融機関口座に振込みを行えず、かつ、一定期間内に返金先金融機関口座の再指定がなされない場合、レコセレは当該購入者に対する販売代金等の返金義務を免れるものとします。
第７条（販売商品に関する責任）
販売商品の不具合等に関する購入者に対する責任は販売者が負うものとします。
販売商品の不具合等が発生した場合、販売者は、自己の責任と費用負担により、販売商品の交換、追送・再送、修理、回収等の適切な処置を講じるものとします。
商品売買契約に基づく販売者の販売商品の引渡義務は、販売商品の受取店舗への配達が完了した時点において履行されたものとみなされ、販売商品の受取店舗への配達完了後に販売商品について生じた不具合等については、前二項の規定にかかわらず、販売者は一切の責任を負いません。
第８条（販売商品の引取り・保管）
購入者は、販売商品の受取店舗への配達が完了した旨の通知を受け取った場合、通知を受けた期間内における受取店舗の営業時間中に、販売商品を自らの責任において引き取るものとします。
通知された期間内における受取店舗の営業時間中に販売商品を引き取らなかった場合、購入者は、当該商品に関して有する権利を失うものとします。なお、この場合においても当該商品に関する対価の支払義務は消滅しないものとします。
購入者は、販売商品の配送先として指定した受取店舗において、配達が完了した販売商品を、自らの責任において保管するものとします。なお、受取店舗は、購入者が販売商品を受け取り、保管するために、販売者から購入者に無償で提供され、購入者自らの選択により通知された期間内における受取店舗の営業時間中に引き取ることができることに鑑み、販売者、レコセレ及びスペース提供者は、受取店舗での販売商品保管、引取りについて、一切の責任を負わないものとします。
第９条（受取店舗以外での販売商品の引取・保管）
第３条第２項の定めに拘らず、購入者は、レコセレが定めた手続きを経た場合に限り、受取店舗以外の場所（以下「個別受取場所」という）にて販売商品の引取をすることができるものとします。
レコセレは、個別受取場所とすることができる場所について条件を付すことができるものとします。
第７条第３項及び第８条第２項の定めは、本条に基づき個別受取場所での引取について準用するものとします。その場合、「受取店舗」を「個別受取場所」と読み替えるものとします。
第１０条（問合せ等）
購入者は、販売商品に関する情報、取引条件、商品売買契約等について、レコセレが別途定める手続きに従い、レコセレに連絡することができます。但し、レコセレは、当該連絡に対して、これを解決する義務を負うものではありません。
レコセレの利用方法その他レコセレが提供するサービスについて、購入者は、レコセレが別途定める手続きに従い、レコセレに問い合わせることができます。
第１１条（存続条項）
第５条（販売代金等の支払い）第２項及び第３項、第６条（返金）、第７条（販売商品に関する責任）、第８条（受取りに関する責任）、第１０条（問合せ等）並びに本条の各規定は、レコセレ利用契約の終了後もなお有効に存続するものとします。
以上
２０ 2  2年１0月6日 制定
Copyright© 2022 Recosele Inc. All Rights Reserved

`,
    }),
  };
</script>
<style>
  div {
    white-space: pre-line;
  }
</style>
